import gsap from 'gsap';
import Glide from '@glidejs/glide';
// Set module variables;
const activeClass = 'is-active';
let carousel;
const textBoxes = document.querySelectorAll('.js-home-carousel-text-content');

function initButtons() {
  const buttons = document.querySelectorAll('.js-home-carousel-button');
  buttons.forEach(button => {
    button.addEventListener('click', function() {
      button.dataset.direction == 'previous' ? carousel.go('<') : carousel.go('>');
    });
  });
}

function updateMaxTextBoxHeight() {
  let maxHeight = 0;
  // Temporarily set each box's initial height back to auto, otherwise you'll just whatever previous the min-height was set to:
  textBoxes.forEach(element => gsap.set(element, {minHeight: 'auto'}));
  textBoxes.forEach(element => {
    // Get the 'initial' height of the element
    const height = gsap.getProperty(element, 'height');
    // Update maxHeight if this element's height is larger
    if (height > maxHeight) {
      maxHeight = height;
    }
  });
  // Then set each textBox's min-height to whatever the tallest box is:
  textBoxes.forEach(element => gsap.set(element, {minHeight: maxHeight}));
}

function updateActiveLink(move) {
  const star = document.getElementById('star-svg-hexagon');
  const circles = document.querySelectorAll('.js-star-svg-circle');
  const tl = gsap.timeline();
  let prevCircle;
  let prevTextBox;
  if (move.direction === '<') {
    prevCircle = circles[carousel.index === circles.length - 1 ? 0 : carousel.index + 1];
    prevTextBox = textBoxes[carousel.index === textBoxes.length - 1 ? 0 : carousel.index + 1];
  } else {
    prevCircle = circles[carousel.index === 0 ? circles.length - 1 : carousel.index - 1];
    prevTextBox = textBoxes[carousel.index === 0 ? textBoxes.length - 1 : carousel.index - 1];
  }
  const nextCircle = circles[carousel.index];
  const nextTextBox = textBoxes[carousel.index];
  const origin = 'center center';
  const start = 'start';
  // Animate star and text box at the same time
  tl.add('start');
  tl.to(star, { 
    duration: 0.2, 
    rotate: `${move.direction === "<" ? "-" : "+"}=60`,
    opacity: 1,
    ease: "power1.inOut", 
    transformOrigin: origin,
  });
  tl.to(prevCircle, {
    opacity: 0,
    transformOrigin: origin,
    duration: 0.2,
    ease: "back.out(2)",
  }, start);
  tl.fromTo(nextCircle, {
    opacity: 0,
    scale: 0.8,
    transformOrigin: origin,
  }, {
    opacity: 1,
    scale: 1,
    transformOrigin: origin,
    duration: 0.2,
    ease: "back.out(2)",
  }, start);
  tl.set(prevTextBox, {
    position: 'absolute',
    opacity: 0,
  }, start);
  tl.set(nextTextBox, {
    position: 'relative',
    opacity: 1,
  }, start);
  tl.fromTo(nextTextBox.children, {
    opacity: 0,
    y: (index) => {
      return index === 0 ? 0 : 10;
    },
  }, {
    opacity: 1,
    y: 0,
    duration: 0.2,
    stagger: 0.1,
    ease: "power1.out",
  }, start);
  // Don't need to animate the find out more links, just show the right one
  const links = document.querySelectorAll('.js-home-carousel-link');
  links.forEach(link => link.classList.remove(activeClass));
  links[carousel.index].classList.add(activeClass);
}

export default function() {
  const carouselEl = document.getElementById('home-carousel');
  if (!carouselEl) return;
  carousel = new Glide(carouselEl, {
    type: 'carousel',
    gap: 48,
    dragThreshold: false
  });
  // Set up external buttons to control carousel  
  carousel.on('mount.after', function() {
    updateMaxTextBoxHeight();
    initButtons();
  });
  carousel.on('run', updateActiveLink);
  carousel.on('resize', updateMaxTextBoxHeight);
  carousel.mount();
}