import gsap from 'gsap';
const accordionSelector = '.js-accordion';
const contentSelector = '.js-accordion-content';
const accordions = Array.from(document.querySelectorAll(accordionSelector));
const filterSelector = '.js-accordions-filter';
const openClass = 'is-open';
const filters = document.querySelectorAll(filterSelector);
const activeClass = 'accordion-is-visible';
const container = document.getElementById('accordions-items');
const lastClass = 'accordion-is-last';
let accordionArray = [];
let containerMinHeight;

function closeAll() {
  accordionArray.forEach(item => item.closeTl.invalidate().restart().progress(1));
  accordions.forEach(item => item.classList.remove(openClass));
}

function updateContainerMinHeight() {
  // If the new container height is greater than the current container height set that to be the new min height
  // This prevents the container height 'jumping' to a shorter height if there a fewer items when you change filters
  const newContainerHeight = container.offsetHeight;
  newContainerHeight > containerMinHeight ? containerMinHeight = newContainerHeight : containerMinHeight;
  gsap.set(container, {minHeight: containerMinHeight})
}

function filterItems(e) {
  const filter = e.target.closest(filterSelector);
  const filterValue = filter.value;
  // const active = document.querySelectorAll(`.${activeClass}`);
  const newActive = accordions.filter(item => item.dataset.categories.includes(filterValue));
  const tl = gsap.timeline();
  // updateContainerMinHeight();
  closeAll();
  accordions.forEach(item => item.classList.remove(activeClass, lastClass));
  // active.forEach(item => item.classList.remove(activeClass));
  newActive.forEach((item, index) => {
    item.classList.add(activeClass);
    if (index === newActive.length -1) item.classList.add(lastClass);
  });
  updateContainerMinHeight();
  tl.fromTo(newActive, {
    y: 20,
    autoAlpha: 0,
  }, {
    y: 0,
    autoAlpha: 1,
    duration: 0.4,
    stagger: 0.1,
    ease: 'back.out(1.4)',
    clearProps: 'y,autoAlpha',
  });
}

function toggleAccordion(e) {
  const accordion = e.target.closest(accordionSelector);
  const accordionObject = accordionArray.find(item => item.el === accordion);
  const isActive = accordion.classList.contains(openClass);
  if (isActive) {
    accordion.classList.remove(openClass);
    accordionObject.closeTl.invalidate().restart();
  } else {
    accordion.classList.add(openClass);
    accordionObject.openTl.restart();
  }
}

function initAccordion(el) {
  const button = el.querySelector('.js-accordion-button');
  const content = el.querySelector(contentSelector);
  const imageCol = el.querySelector('.js-accordion-image-col');
  const image = el.querySelector('.js-accordion-image');
  const ease = 'power1.inOut';
  const duration = 0.2;
  const openTl = gsap.timeline({paused: true});
  openTl.to(imageCol, {
    width: '100%',
    duration: duration,
    ease: ease
  });
  openTl.to(content, { 
    height: 'auto', 
    duration: duration, 
    ease: ease
  });
  openTl.to(image, { 
    autoAlpha: 1, 
    duration: duration, 
    ease: ease
  });
  const closeTl = gsap.timeline({paused: true});
  closeTl.to(image, { 
    autoAlpha: 0, 
    duration: duration, 
    ease: ease,
  });
  closeTl.to(content, { 
    height: 0, 
    duration: duration, 
    ease: ease,
  });
  closeTl.to(imageCol, {
    width: 0,
    duration: duration,
    ease: ease,
  });
  const accordionObject = {
    el,
    openTl,
    closeTl
  };
  accordionArray.push(accordionObject);
  button.addEventListener('click', toggleAccordion);
}

export default function() {
  if (!accordions.length) return;
  accordions.forEach(accordion => initAccordion(accordion));
  filters.forEach(filter => filter.addEventListener('change', filterItems));
  containerMinHeight = container.offsetHeight;
}