import gsap from 'gsap';
import Glide from '@glidejs/glide';
import animations from './animations';
// Set module variables;
const buttonSelector = '.js-filter-carousel-button';
const arrowSelector = '.js-filter-carousel-button-arrow';
const filterSelector = '.js-carousel-filter';
const carouselSelector = '.js-filter-carousel';
const containerSelector = '.js-filter-carousel-container';
const activeClass = 'carousel-is-active';
const tempActiveClass = 'carousel-is-temp-active';
let carouselEls;
let carouselObjects = [];
let mountedCount = 0;
let buttons;

function setContainerHeight() {
  // Temporarily add active class to all containers so we can get the height of the tallest carousel
  let maxHeight = 0;
  carouselObjects.forEach(item => {
    const { carouselContainer, carouselInstance } = item;
    carouselContainer.classList.add(tempActiveClass);
    carouselInstance.enable().update();
    const height = carouselContainer.offsetHeight;
    if (height > maxHeight) {
      maxHeight = height;
    }
    if (carouselContainer.classList.contains(tempActiveClass) && !carouselContainer.classList.contains(activeClass)) {
      carouselInstance.disable();
    }
    carouselContainer.classList.remove(tempActiveClass);
  });
  carouselObjects.forEach(function(item) {
    const { carouselContainer } = item;
    gsap.set(carouselContainer, {minHeight: `${maxHeight}px`});
  });
}

function updateActiveCarousel(e) {
  // Hide and disable current carousel
  const activeCarouselContainer = document.querySelector(`.${activeClass}`);
  const activeCarouselObject = carouselObjects.find(carouselObject => carouselObject.carouselContainer === activeCarouselContainer);
  const activeCarouselInstance = activeCarouselObject.carouselInstance;
  activeCarouselInstance.disable();
  activeCarouselContainer.classList.remove(activeClass);
  // Show carousel that matches clicked filter
  const filter = e.target.closest(filterSelector);
  const carouselObject = carouselObjects.find(carouselObject => carouselObject.carouselEl.id === `carousel-${filter.value}`);
  // ES6 syntax for getting object property: https://wesbos.com/destructuring-objects
  const { carouselContainer, carouselInstance } = carouselObject;
  // carouselContainer.classList.add(activeClass);
  carouselInstance.enable();
  // Rewind to start of carousel when re-showing it
  carouselInstance.go('<<');
  carouselContainer.classList.add(activeClass);
  const animEls = Array.from(carouselContainer.querySelectorAll('.js-anim-batch'));
  animations.anim(animEls);
  // Update buttons
  updateButtons.call(carouselObject);
}

function initFilters() {
  const filters = document.querySelectorAll(filterSelector);
  filters.forEach(filter => filter.addEventListener('change', updateActiveCarousel))
}

function buttonAnim(e) {
  const button = e.target.closest(buttonSelector);
  if (button.disabled) return;
  const arrow = button.querySelector(arrowSelector);
  const tl = gsap.timeline();
  tl.fromTo(arrow, {
    xPercent: 0,
    opacity: 1,
  }, {
    xPercent: () => button.dataset.direction == 'previous' ? -50 : 50,
    opacity: 0,
    duration: 0.15,
    ease: "none"
  }).fromTo(arrow, {
    xPercent: () => button.dataset.direction == 'previous' ? 50 : -50,
    opacity: 0,
  }, {
    xPercent: 0,
    opacity: 1,
    duration: 0.15,
    ease: "none"
  })
}

function moveCarousel(e) {
  const button = e.target.closest(buttonSelector);
  const carouselObject = carouselObjects.find(carouselObject => carouselObject.carouselContainer.classList.contains(activeClass));
  const { carouselInstance } = carouselObject;
  button.dataset.direction == 'previous' ? carouselInstance.go('<') : carouselInstance.go('>');
}

function initControls() {
  buttons.forEach(button => {
    // As we've disabled rewinding on our carousels, we should disable the 'previous' button when the carousel first loads so we provide the user with a visual cue that they can't go back past the first card/slide
    if (button.dataset.direction === 'previous') button.disabled = true;
    button.addEventListener('click', moveCarousel);
    button.addEventListener('mouseenter', buttonAnim);
    button.addEventListener('focus', buttonAnim);
  });
}

function updateButtons() {
  const { carouselInstance, total } = this;
  const prevButton = buttons.find(button => button.dataset.direction === 'previous');
  const nextButton = buttons.find(button => button.dataset.direction === 'next');
  // Enable both buttons by default.
  buttons.forEach(button => button.disabled = false);
  // Then test for the below..
  if (total <= 1) {
    // Disable both buttons
    prevButton.disabled = true;
    nextButton.disabled = true;
  } else if (carouselInstance.index === 0) {
    // Disable start button
    prevButton.disabled = true;
  } else if (carouselInstance.index === total - 1) {
    // Disable end button
    nextButton.disabled = true;
  }
}

function initCarousel(el) {
  const container = el.closest(containerSelector);
  const total = el.querySelectorAll('.js-carousel-item').length;
  const carousel = new Glide(el, {
    rewind: false,
    dragThreshold: false,
    gap: 24,
    perView: 3,
    breakpoints: {
      960: {
        perView: 2
      },
      550: {
        perView: 1,
      }
    }
  });
  // Create carouselObject for later
  const carouselObject = {
    carouselEl: el,
    carouselContainer: container,
    carouselInstance: carousel,
    total: total
  };
  // Set up external buttons to control carousel  
  carousel.on('mount.after', function() {
    // Push carousels into array to keep track of multiple carousels later
    carouselObjects.push(carouselObject);
    mountedCount++;
    // Check if all carousels are mounted
    if (mountedCount === carouselEls.length) {
      initControls();
      initFilters();
      setContainerHeight();
    }
  });
  carousel.on('resize', setContainerHeight);
  carousel.on('run', updateButtons.bind(carouselObject));
  carousel.mount();
  if (!container.classList.contains(activeClass)) {
    carousel.disable();
  }
}

export default function() {
  carouselEls = document.querySelectorAll(carouselSelector);
  if (!carouselEls) return;
  buttons = Array.from(document.querySelectorAll(buttonSelector));
  carouselEls.forEach(el => initCarousel(el));
}