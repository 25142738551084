import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const body = document.body;

export default function() {
  ScrollTrigger.create({
    onEnter: () => body.classList.add('page-is-scrolled'),
    onLeaveBack: () => body.classList.remove('page-is-scrolled')
  });
}