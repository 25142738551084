import gsap from 'gsap';
import Glide from '@glidejs/glide';
// Set module variables;
const buttonSelector = '.js-card-carousel-button';
const arrowSelector = '.js-card-carousel-button-arrow';
const carouselContainer = document.getElementById('card-carousel');
let carousel;
let buttons;
let total;

function updateButtons() {
  const prevButton = buttons.find(button => button.dataset.direction === 'previous');
  const nextButton = buttons.find(button => button.dataset.direction === 'next');
  // Enable both buttons by default.
  buttons.forEach(button => button.disabled = false);
  // Then test for the below..
  if (total <= 1) {
    // Disable both buttons
    prevButton.disabled = true;
    nextButton.disabled = true;
  } else if (carousel.index === 0) {
    // Disable start button
    prevButton.disabled = true;
  } else if (carousel.index === total - 1) {
    // Disable end button
    nextButton.disabled = true;
  }
}

function buttonAnim(e) {
  const button = e.target.closest(buttonSelector);
  if (button.disabled) return;
  const arrow = button.querySelector(arrowSelector);
  const tl = gsap.timeline();
  tl.fromTo(arrow, {
    xPercent: 0,
    opacity: 1,
  }, {
    xPercent: () => button.dataset.direction == 'previous' ? -50 : 50,
    opacity: 0,
    duration: 0.15,
    ease: "none"
  }).fromTo(arrow, {
    xPercent: () => button.dataset.direction == 'previous' ? 50 : -50,
    opacity: 0,
  }, {
    xPercent: 0,
    opacity: 1,
    duration: 0.15,
    ease: "none"
  })
}

function moveCarousel(e) {
  const button = e.target.closest(buttonSelector);
  button.dataset.direction == 'previous' ? carousel.go('<') : carousel.go('>');
}

function initControls() {
  buttons.forEach(button => {
    // As we've disabled rewinding on our carousels, we should disable the 'previous' button when the carousel first loads so we provide the user with a visual cue that they can't go back past the first card/slide
    if (button.dataset.direction === 'previous') button.disabled = true;
    button.addEventListener('click', moveCarousel);
    button.addEventListener('mouseenter', buttonAnim);
    button.addEventListener('focus', buttonAnim);
  });
}

function initCarousel(el) {
  carousel = new Glide(el, {
    rewind: false,
    dragThreshold: false,
    gap: 24,
    perView: 3,
    breakpoints: {
      960: {
        perView: 2
      },
      550: {
        perView: 1
      }
    }
  });
  // Set up external buttons to control carousel  
  carousel.on('mount.after', initControls);
  carousel.on('run', updateButtons);
  carousel.mount();
}

export default function() {
  if (!carouselContainer) return;
  total = carouselContainer.querySelectorAll('.js-card-carousel-item').length;
  buttons = Array.from(document.querySelectorAll(buttonSelector));
  initCarousel(carouselContainer);
}