import animations from "./animations";
const container = document.getElementById('paginate-blocks');
const loadMoreButton = document.querySelector('.js-load-more-blocks');
const blocks = Array.from(document.querySelectorAll('.js-paginate-block'));
let startIndex = 0;
// N.B. This has to equal batch length in the twig partial: paginate-reports.twig
let batch = 8;

function loadMore() {
  startIndex+=batch;
  // Check if next batch length exceeds remaining number of elements in the array.
  // If so, then set the end batch index to be the same as the total number of elements, so you get the correct last range.
  const endIndex = (startIndex + batch) >= blocks.length ? blocks.length : startIndex + batch;
  const nextBlocks = blocks.slice(startIndex, endIndex);
  nextBlocks.forEach(block => block.classList.add('js-paginate-block-active'));
  animations.anim(nextBlocks);
  if (startIndex + batch >= blocks.length) {
    loadMoreButton.parentElement.style.display = 'none';
  }
}

export default function() {
  if (!container) return;
  loadMoreButton.addEventListener('click', loadMore);
}