import axios from 'axios';
import gsap from 'gsap';
const form = document.getElementById('filter-cards-form');
const filterSelector = '.js-filter-cards-filter';
const filters = document.querySelectorAll(filterSelector);
const loadingClass = 'loading-more-items';
const ajaxContainerSelector = 'ajax-filter-cards-container';
const ajaxContainer = document.getElementById(ajaxContainerSelector);
const cardSelector = '.js-ajax-card';
const newCardSelector = 'js-ajax-anim-new-card';
const paginateSelector = '#filter-cards-more';

function anim(animArray) {
  gsap.fromTo(animArray, {
    y: 10,
    autoAlpha: 0,
  }, {
    y: 0,
    autoAlpha: 1,
    duration: 0.6,
    stagger: 0.1,
    ease: "power1.out",
  });
}

function ajax(options) {
  const { url, paginate = false, updateHistory = true } = options;
  const body = document.body;
  document.body.classList.add(loadingClass);
  axios.get(url).then((res) => {
    // if (history && updateHistory) {
    //   history.pushState('', '', url);
    // }
    const loadMore = document.querySelector(paginateSelector);
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res.data, "text/html");
    const newContainer = resHTML.getElementById(ajaxContainerSelector);
    const newCards = newContainer.querySelectorAll(cardSelector);
    newCards.forEach(card => card.classList.add(newCardSelector));
    const newContent = newContainer.innerHTML;
    // Add temporary class to new cards in so we only animate those/don't re-animate existing cards:
    const newAnimateEls = newContainer.querySelectorAll('.js-anim-batch');
    newAnimateEls.forEach(item => item.classList.add(newCardSelector));
    if (!paginate) {
      // Prevent height 'jump' when swapping content
      const height = ajaxContainer.offsetHeight;
      ajaxContainer.style.height = `${height}px`;
      ajaxContainer.innerHTML = '';
    }
    ajaxContainer.insertAdjacentHTML('beforeend', newContent);
    ajaxContainer.style.height = `auto`;
    const addedItems = document.querySelectorAll(`.${newCardSelector}`);
    anim(addedItems);
    addedItems.forEach(card => card.classList.remove(newCardSelector));
    if (paginate) {
      loadMore.parentElement.remove();
    }
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    body.classList.remove(loadingClass);
  });
}

function paginate(link) {
  const url = decodeURIComponent(link.href);
  ajax({url: url, paginate: true});
}

function handle(e) {
  if (e.type === 'submit') {
    console.log('here');
    e.preventDefault();
  }
  const checked = form.querySelector('.js-filter-cards-filter:checked');
  const href = window.location.href;
  // Always remove page number when updating filters
  const baseUrl = href.includes('/page') ? href.split('/page')[0] : href.split('?')[0];
  const newUrl = new URL(baseUrl);
  let params = newUrl.searchParams;
  params.set('filter', checked.value);
  ajax({url: newUrl});
}

export default function() {
  // Note: there should only be one filters form per page
  if (!form) return;
  document.addEventListener('click', function(e) {
    // Allow command-click and control-click to open new tab
    // http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
    if (e.metaKey || e.ctrlKey) {
      return;
    }
    const clicked = e.target;
    const paginateLink = clicked.closest(paginateSelector);
    if (paginateLink) {
      e.preventDefault();
      paginate(paginateLink);
    }
  });

  window.addEventListener('popstate', (e) => {
    window.location.reload();
  });

  form.addEventListener('submit', handle);
  filters.forEach(filter => filter.addEventListener('change', handle));
}
