export default function() {
  const body = document.querySelector('body');

  //Open Modal
  const openModal = function(button) {
    const modalId = button.id;
    const modal = document.querySelector(`[data-modal-id="${modalId}"]`);
    modal.classList.add("story-modal-is-open");
    body.classList.add("story-modal-is-active");
  };

  document.addEventListener("click", function(e) {
    const target = e.target;
    const openModalBtn = target.closest('.js-story-modal-btn-open');
    if (!openModalBtn) return;
    openModal(openModalBtn);
  })

  //Close Modal
  document.addEventListener("click", function(e) {
    const target = e.target;
    const closeModalBtn = target.closest('.js-story-modal-btn-close');
    if (!closeModalBtn) return;
    closeModal(closeModalBtn);
  })

  const closeModal = function() {
    const currentOpenModal = document.querySelector('.story-modal-is-open');
    currentOpenModal.classList.remove('story-modal-is-open');
    body.classList.remove("story-modal-is-active");
  };

  // Overlay
  document.addEventListener("click", function(e) {
    const target = e.target;
    const overlay = target.closest(".js-story-modal-overlay");
    if (!overlay) return;
    closeModal();
  });
}

