const navToggleBtn = document.getElementById('nav-btn');

function toggleNav() {
  document.body.classList.toggle('nav-is-active');
}

export default function() {
  if (!navToggleBtn) return;
  navToggleBtn.addEventListener('click', toggleNav);
}
